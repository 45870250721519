<template>
    <div class="app-header">
        <a href="#" class="app-logo"><img :src="require(`@/assets/logo.png`)" alt=""  @click="goToMainPage"></a>
        <AppMainHeaderNavigation />
        <div class="massagist-balance-block">
            <div class="balance-state"><span class="lable">Баланс: </span><span class="money">{{ +finance.bonus + +finance.balance }} руб</span></div>
            <div class="balance-action" @click="$emit('openPayment')"><a class="pay">пополнить</a></div>
        </div>
        <AppRegularBtn light text="выход" class="header-login" @click="logout" />
    </div>
</template>

<script>
import AppMainHeaderNavigation from "@/components/navigation/app-main-header-navigation.vue";
import AppRegularBtn from "@/components/buttons/app-regular-btn.vue";
import massagistApi from "@/api/massagist.js";
import { reactive } from '@vue/reactivity';
import { useStore } from "vuex";
import { onMounted } from '@vue/runtime-core';
    export default {
        components: {
            AppMainHeaderNavigation,
            AppRegularBtn
        },
        methods: {           
            goToMainPage(){
                this.$router.push('/')
            },
            logout(){
                localStorage.removeItem('user');
                this.$store.dispatch('logout');
                this.$router.push('/');
                this.$store.dispatch('dropCurrentPageInMobyleNavigationPanel');
            },

        },
        setup(){
        const store = useStore();
        let finance = reactive({
            balance: 0,
            bonus: 0,
            massagistID: store.state.user.user.id,
            token: store.state.user.token,
            getFinance: async () => {
                
                await massagistApi.getMassagistBalance({ token: finance.token, massagist_id: finance.massagistID })
                .then(response => {
                    console.log(response.data.balance)
                    console.log(response.data.bonus)
                    finance.balance = response.data.balance;
                    finance.bonus = response.data.bonus;
                })
                .catch(err => console.log(err));
            }
        });

        onMounted(finance.getFinance())

        return {
            finance
        }
    }     
    }
</script>

<style lang="scss" scoped>
    .app-header{
        width: 100%;
        padding: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        .app-logo{
            img{
                // width: 30%;
            }
        }
        .massagist-balance-block{
            font-size: 1em;
            font-weight: 700;
            display: none;
            .balance-state{
                color: $black;
            }
            .balance-action{
                color: $accent-dark;
                cursor: pointer;
            }
        }
        .header-login{
            display: none;
        }
        .header-registration{
            display: none;
        }
    }
    @media screen and(min-width: 768px){
       .app-header{
           justify-content: space-between;
           .header-login{
               display: block;
           }
           .massagist-balance-block{
                display: block;
            }
        
        }
    }
    @media screen and(min-width: 1024px){
       .app-header{
           justify-content: space-around;
           .header-login{
               display: block;
               padding: 0.5em 1em;
               border: 1px solid $primary-darken;
               font-size: 0.8em;
           }
           .header-registration{
               padding: 0.5em 1em;
               font-size: 0.8em;
           }
        
        }
    }
    @media screen and(min-width: 1250px){
       .app-header{
           justify-content: space-between;
           .header-login{
               display: block;
               padding: 1em 2em;
               border: 2px solid $primary-darken;
               font-size: 1em;
           }
           .header-registration{
               padding: 1em 2em;
               font-size: 1em;
           }
        
        }
    }
</style>