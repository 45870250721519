<template>
    <div class="app-mobile-aside-menu" :class="{ 'opened': isOpened }">
        <AppCityNavigation />
        <div class="navigation">
            <div class="person">
                <div class="avatar">
                    <img :src="avatar" alt="">
                </div>
                <div class="name">{{ name }}</div>
            </div>
            <div class="nav-items-list">
                <div class="list-item" :class="{ 'active': item.active }" v-for="item in navItems" :key="item.id" @click="$emit('navigation', item)">{{item.name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import AppCityNavigation from "@/components/navigation/app-city-navigation.vue";
import domain from "@/api/domain.js";

    export default {
        components: {
            AppCityNavigation,
            
        },
        props: {
            isOpened: {
                type: Boolean
            }
        },
        data(){
            return{
                navItems: []
            }
        },
        methods: {
            checked(data){
                console.log(data)
            },
            getNavItems(){
                this.navItems = this.$store.state.mobilePrivateNavigation;
            }
        },
        computed: {
            checkScreenHeght(){
                return window.innerHeight;
            },
            name(){
                return this.$store.state.user.user.username
            },
            avatar(){
                let avatarPath = `${domain.massagistPhotosPath}${this.$store.state.user.user.id}/${this.$store.state.user.user.mainPhoto}`;
                return avatarPath;
            }
        },
        beforeMount(){
            this.getNavItems();
        }
    }
</script>

<style lang="scss" scoped>
    .app-mobile-aside-menu{
        position: fixed;
        top: 0;
        left: 0;
        width: 280px;
        height: 100%;
        background-color: $primary;
        border-right: 1px solid $white;
        transform: translateX(-100%);
        transition: 0.4s ease;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 2em 0;
        .navigation{
            width: 90%;
            height: auto;
            background-color: $accent;
            border-radius: 0.5em;
            .person{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 1em 0;
                .avatar{
                    width: 60px;
                    height: 60px;
                    border-radius: 100%;
                    background-color: $accent-dark;
                    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
                    margin-bottom: 1em;
                    position: relative;
                    overflow: hidden;
                    img{
                        max-width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .name{
                    color: $white;
                    font-size: 1.2em;
                }
            }
            .nav-items-list{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                .list-item{
                    width: 100%;
                    padding: 1em 0;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 1.3em;
                    color: $white;
                     border-top: 1px solid $white;
                    &:last-child{
                    border-bottom: 1px solid $white;                       
                    }
                }
                .active{
                    background-color: $accent-dark;
                }
            }
    
        }
    }
    .opened{
        transform: translateX(0);
    }

    @media screen and(max-height: 667px){
        .app-mobile-aside-menu{
            padding: 1em 0;
            justify-content: space-between;
 
        }
    }
</style>