<template>
    <AppLayoutUserPrivate :isAsideOpened="isAsideOpened">
        <template v-slot:header>
            <AppHeaderUserPrivate @openPayment="openPayment($event)" />
            <Payment
                v-if="isPaymentOpen" 
                @closePayment="closePayment($event)" />
        </template>
        <template v-slot:main>
            <h3 class="title">Личный кабинет мастера</h3>
            <router-view />
        </template>
        <template v-slot:aside>
            <AppCityNavigation />
            <div class="navigation">
                <div class="person">
                    <div class="avatar">
                        <img :src="avatar" alt="">
                    </div>
                    <div class="name">{{ name }}</div>
                </div>
                <div class="nav-items-list">
                    <div class="list-item" :class="{ 'active': item.active }" v-for="item in navItems" :key="item.id" @click="goToPage(item)">{{item.name}}</div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <AppFooter />
            <AppNavigationMobilePrivate @sendEvent="goToPage($event)" />
        </template>
    </AppLayoutUserPrivate>
</template>

<script>
    import AppLayoutUserPrivate from "@/layouts/user/private/app-layout-user-private.vue";
    import AppHeaderUserPrivate from "@/sections/app-header-user-private.vue";
    import AppFooter from "@/sections/app-footer.vue";
    import AppNavigationMobilePrivate from "@/sections/app-navigation-mobile-private.vue";
    import AppCityNavigation from "@/components/navigation/app-city-navigation.vue";
    import Payment from "@/payment/payment.vue";
    import domain from "@/api/domain.js";
    export default {
        name: "MassagistPersonalPage",
        components: {
            AppLayoutUserPrivate,
            AppHeaderUserPrivate,
            AppFooter,
            AppNavigationMobilePrivate,
            AppCityNavigation,
            Payment
        },
        data(){
            return {
                isAsideOpened: true,
                navItems: null,
                isPaymentOpen: false
            }
        },
        methods: {
            goToPage(e){
                this.$store.dispatch('setCurrentPageInMobyleNavigationPanel', e.id)
               this.$router.push(`/massagist-personal-page/${this.$store.state.user.user.id}/${e.link}`)
            },
             getNavItems(){
                this.navItems = this.$store.state.mobilePrivateNavigation;
            },
            getName(){
                this.name = this.$store.state.user.user.username;
            },
            closePayment(e){
                this.isPaymentOpen = false
            },
            openPayment(e){
                this.isPaymentOpen = true
            }
        },
        computed:{
            name(){
                return this.$store.state.user.user.username;
            },
            avatar(){
                if(this.$store.state.user.user.mainPhoto === 'user.png'){
                    return `${domain.massagistServicePhotoPath}/user.png`;
                } else {
                    return `${domain.massagistPhotosPath}${this.$store.state.user.user.id}/${this.$store.state.user.user.mainPhoto}`;
                }
            }
        },
        beforeMount(){
            this.getNavItems();
        }
        
    }
</script>

<style lang="scss" scoped>
    .title{
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 2em;
    }
      .navigation{
            width: 90%;
            height: auto;
            background-color: $accent;
            border-radius: 0.5em;
            margin-top: 3em;
            .person{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 1em 0;
                .avatar{
                    width: 60px;
                    height: 60px;
                    border-radius: 100%;
                    background-color: $accent-dark;
                    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
                    margin-bottom: 1em;
                    position: relative;
                    overflow: hidden;
                    img{
                        max-width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .name{
                    color: $white;
                    font-size: 1.2em;
                }
            }
            .nav-items-list{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                .list-item{
                    width: 100%;
                    padding: 1em 0;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 1.3em;
                    color: $white;
                    border-top: 1px solid $white;
                    cursor: pointer;
                    &:last-child{
                    border-bottom: 1px solid $white;                       
                    }
                }
                .active{
                    background-color: $accent-dark;
                }
            }
    
        }
</style>