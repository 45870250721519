<template>
    <div class="app-navigation-mobile" :class="{ 'navigation-mobile-translated' : isSubMenuOpened }">
        <AppHomeIcon @click="goToPage.home"/>
        <AppLogoutIcon @action="goToPage.home"/>
        <AppHumBtn @action="openMobileMenu" :isHumActive="isHumActive" />
        <div 
            :class="{'back-ground': true, 'back-ground-active': isMobileMenuOpened }"
            @click="openMobileMenu">
        </div>
        <AppMobileAsideMenu :isOpened="isMobileMenuOpened" @navigation="sendEvent($event)"/>
    </div>
</template>

<script>
import AppHumBtn from "@/components/buttons/app-hum-menu-btn.vue";
import AppMobileAsideMenu from "@/sections/app-mobile-aside-menu-private.vue";
import AppHomeIcon from "@/components/icons/app-home-icon.vue";
import AppLogoutIcon from "@/components/icons/app-logout-icon.vue";
import { useRouter } from "vue-router";
import { ref } from 'vue';
    export default {
        components: {
            AppLogoutIcon,
            AppMobileAsideMenu,
            AppHomeIcon,
            AppHumBtn,
        },
        emits: ['sendEvent'],
        setup(props, { emit }){
            const router = useRouter();
            let isMobileMenuOpened = ref(false);
            const isSubMenuOpened = ref(false);
            const isHumActive = ref(false);
            const openMobileMenu = () => {
                isMobileMenuOpened.value = !isMobileMenuOpened.value;
                isHumActive.value = !isHumActive.value;
            };

            const openSubMenu = () => {
                isSubMenuOpened.value = !isSubMenuOpened.value;
                if(isSubMenuOpened.value){
                    setTimeout(() => {
                        isSubMenuOpened.value = false;
                    }, 3000)
                }
            }
            
            const sendEvent = (e) => {
                emit('sendEvent', e);
                openMobileMenu();
            }

            const goToPage = {
                home: () => router.push('/'),
                registration: () => router.push('/registration'),
                login: () => router.push('/login'),
                favorites: () => router.push('/favorites'),
            }

            return {
                isMobileMenuOpened,
                isSubMenuOpened,
                openMobileMenu,
                openSubMenu,
                goToPage,
                sendEvent,
                isHumActive
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-navigation-mobile{
        height: 10%;
        width: 100%;
        left: 0;
        bottom: 0;
        position: fixed;
        background-color: $primary-dark;
        border-top: 1px solid $white;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 1em;
        transition: 0.3s ease;
        .back-ground{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #8332B170;
            display: none;
        }
        .back-ground-active{
            display: block;
        }
        .heart-icon{
            width: 30px;
            height: 30px;
            position: relative;
            .counter{
                position: absolute;
                left: 50%;
                top: 50%;
                color: $white;
                font-size: 0.8em;
                transform: translate(-22%, -35%)
            }
        }
        .sub-menu{
            width: 100%;
            height: 210%;
            position: absolute;
            left: 0;
            bottom: -210%;
            background-color: $primary-darken;
            border-top: 2px solid $white;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            button{
                padding: 1em 2em;
                border: transparent;
                border-radius: 0.4em;
                text-transform: uppercase;
                background-color: $primary;
                color: $black;
                font-weight: 700;
            }
        }
    }


    .navigation-mobile-translated{
        transform: translateY(-200%);
    }

    @media screen and(min-width: 768px){
         .app-navigation-mobile{            
            justify-content: space-around;
            
        }
    }
    @media screen and(min-width: 1024px){
         .app-navigation-mobile{            
            display: none;
            
        }
    }
</style>