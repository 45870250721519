<template>
    <div class="app-layout-user-private">
         <header>
            <slot name="header"></slot>
        </header>        
        <main>
            <slot name="main"></slot>
        </main>
        <aside>
            <slot name="aside"></slot> 
        </aside>  
        <footer>
            <slot name="footer"></slot>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            isAsideOpened: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-layout-user-private{
        display: grid;
        grid-template-rows: repeat(12, auto);
        position: relative;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        align-items: center;
        justify-items: center;

        header{
            grid-row: 1 / 2;
            width: 100%;
        }
        main{
            grid-row: 2 / 3;
            width: 100%;
        }
        aside {
           display: none;
        }
        footer {
            grid-row: 3 / 4;
            width: 100%;
        }
    }

    @media screen and(min-width: 1024px){
        .app-layout-user-private{
            grid-template-columns: repeat(12, 1fr);

            header{
                grid-column: 1 / 13;
            }
            aside {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                grid-row: 2 / 3;
                grid-column: 1 / 4;
                align-self: start;
                width: 80%;
            }
            main{
                grid-column: 4 / 13;
            }
            footer {
                grid-column: 1 / 13;
            }

        }
    }
</style>